import { useEffect, useState } from "react";
import styled from "styled-components";
import "./style.css";
import { Link } from "react-router-dom";

import * as anchor from "@project-serum/anchor";

import { Commitment, Connection, LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { useWallet } from "@solana/wallet-adapter-react";
import bs58 from "bs58";
import React, { FC, useCallback } from "react";
import { sign } from "tweetnacl";
import DiscordVerificationForm from "./DiscordVerificationForm";

import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
  CandyMachineAccount,
  SetupState,
  createAccountsForMint,
  getCollectionPDA,
} from "./candy-machine";
import { checkTokenExpire } from "./helpers/helpers";
import { toast } from "react-toastify";
import axios from "axios";
import { getAtaForMint, toDate } from "./helpers/utils";

export const SignMessageButton: FC = () => {
  const { publicKey, signMessage } = useWallet();

  const onClick = useCallback(async () => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes
      var inst = {
        id: "9yfnGB76bdzUNegNd17AetAD9p8Lca8w7Hf1CntJMSHu",
        cmd: "freeze",
      };
      const message = new TextEncoder().encode(JSON.stringify(inst));
      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes()))
        throw new Error("Invalid signature!");

      alert(`Message signature: ${bs58.encode(signature)}`);

      var instruction = {
        update: [
          {
            instruction: inst,
            signature: bs58.encode(signature),
            pubkey: publicKey.toBase58(),
          },
        ],
      };

      fetch(
        "https://boyw9caow7.execute-api.us-west-2.amazonaws.com/prod/parasites/update/",
        {
          method: "POST",
          body: JSON.stringify(instruction),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      ).then((response) => {
        console.log(response);
        //alert(response);
      });
    } catch (error: any) {
      alert(`Signing failed: ${error?.message}`);
    }
  }, [publicKey, signMessage]);

  return signMessage ? (
    <button onClick={onClick} disabled={!publicKey}>
      Sign Message
    </button>
  ) : null;
};

const ConnectButton = styled(WalletDialogButton)``;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isMinting, setIsMinting] = useState(false);
  const [verificationtoken, setVerificationtoken] = useState(null);
  const [nftsInWallet, setNftsInWallet] = useState<any[]>([]);
  const [dashboardMode, setDashboardMode] = useState(false);
  const [setupTxn, setSetupTxn] = useState<SetupState>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const anchorWallet = useAnchorWallet();
  const { connected: isWalletConnected, publicKey, signMessage } = useWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isActive, setIsActive] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) {
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet as any,
            props.candyMachineId,
            connection
          );
          console.log("Candy machine state: ", cndy);
          let active = cndy?.state.goLiveDate
            ? cndy?.state.goLiveDate.toNumber() < new Date().getTime() / 1000
            : false;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint
            );
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                "There was a problem fetching whitelist token balance"
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log("There was a problem fetching SPL token balance");
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(anchorWallet.publicKey)
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            const limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e: any) {
          let message = e.message;
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              message`Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`;
            } else if (
              e.message.startsWith("failed to get info about account")
            ) {
              message = `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`;
            }
          }
          toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error(
          "Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    },
    [anchorWallet, props.candyMachineId, props.rpcHost]
  );

  useEffect(() => {
    async function getBalance() {
      if (publicKey) {
        const balance = await props.connection.getBalance(publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    }

    getBalance();
  }, [publicKey, props.connection]);

  useEffect(() => {
    refreshCandyMachineState();
  }, [refreshCandyMachineState]);

interface NFT {
  number: number;
}
    interface Instruction {
	wallet: any;
	network: string | undefined;
    }

    const getNFTsInWallet = async (wallet: any) => {
	try {
	    const instruction: Instruction = {
		wallet,
		network: process.env.REACT_APP_SOLANA_NETWORK,
	    };

	    console.log("PREP FETCH");
	    const response = await fetch(
		"https://boyw9caow7.execute-api.us-west-2.amazonaws.com/prod/parasites/",
		{
		    method: "POST",
		    body: JSON.stringify(instruction),
		    headers: {
			"Content-Type": "application/json; charset=UTF-8",
		    },
		}
	    );

	    console.log("Response status:", response.status);
	    console.log("Full response object:", response);

	    const data = await response.json();

	    console.log("Received data:", data);

	    console.log("FETCHED");
	    const nfts: NFT[] = data.parasites;
	    const sortedNfts = nfts.sort((a: NFT, b: NFT) => a.number - b.number);
	    setNftsInWallet(sortedNfts);
	    console.log(sortedNfts);
	} catch (err: any) {
	    console.error(err.message);
	}
    };
  const ChangeNftState = async (tokenid: any, cmd: any) => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes
      var inst = {
        id: tokenid,
        cmd: cmd,
      };
      const message = new TextEncoder().encode(JSON.stringify(inst));
      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes()))
        throw new Error("Invalid signature!");

      //alert(`Message signature: ${bs58.encode(signature)}`);

      var instruction = {
        update: [
          {
            instruction: inst,
            signature: bs58.encode(signature),
            pubkey: publicKey.toBase58(),
          },
        ],
      };

      fetch(
        "https://boyw9caow7.execute-api.us-west-2.amazonaws.com/prod/parasites/update/",
        {
          method: "POST",
          body: JSON.stringify(instruction),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      ).then((response) => {
        console.log(response);
        //alert(response);
        getNFTsInWallet(publicKey.toBase58() || "");
      });
    } catch (error: any) {
      alert(`Signing failed: ${error?.message}`);
    }
  };

  // TODO: signature via wallet
  // TODO: send to API and return verification code
  const verifyDiscord = async (discorduser: any) => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes
      var inst = {
        discorduser: discorduser,
        cmd: "verify",
        project: "solparasites",
      };
      const message = new TextEncoder().encode(JSON.stringify(inst));
      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes()))
        throw new Error("Invalid signature!");

      //alert(`Message signature: ${bs58.encode(signature)}`);

      var instruction = {
        instruction: inst,
        signature: bs58.encode(signature),
        pubkey: publicKey.toBase58(),
      };

      fetch("https://api.solparasites.com/verify", {
        method: "POST",
        body: JSON.stringify(instruction),
        headers: { "Content-Type": "application/json" },
      }).then((response) => {
        response.json().then((data) => {
          console.log(data);
          //alert(data.verificationtoken);
          setVerificationtoken(data.verificationtoken);
        });
      });
    } catch (error: any) {
      alert(`Signing failed: ${error?.message}`);
    }
  };

  const handleMint = async (tokenid: string) => {
    console.log("tokenid", tokenid);
    try {
      setIsMinting(true);
      const isValid = await checkTokenExpire();
      if (!isValid) {
        const inst = "login()";
        const message = new TextEncoder().encode(inst);

        if (signMessage && publicKey) {
          // @ts-ignore
          const signature = await signMessage(message);

          if (
            publicKey &&
            !sign.detached.verify(message, signature, publicKey.toBytes())
          ) {
            throw new Error("Invalid signature!");
          }

          const response = await axios.post(
            "https://api.nftgenie.ai/login",
            {
              instruction: inst,
              signature: bs58.encode(signature),
              pubkey: publicKey.toBase58(),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.data) {
            window.localStorage.setItem("token", response.data.access_token);
          }
        }
      }

      if (isWalletConnected && publicKey && candyMachine?.program) {
        let setupMint: SetupState | undefined;

        if (needTxnSplit && setupTxn === undefined) {
          toast.info("Please sign account setup transaction", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setupMint = await createAccountsForMint(candyMachine, publicKey);
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            toast.info(
              "Setup transaction succeeded! Please sign minting transaction",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else {
            toast.error("Mint failed! Please try again!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        } else {
          toast.error("Please sign minting transaction", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        const mintResult = await mintOneToken(
          candyMachine,
          publicKey,
          [],
          [],
          setupMint ?? setupTxn
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              "processed"
            );
          console.log("Metadata status: ", !!metadataStatus);
        }
        if (status && !status.err && mintResult) {
          const token = window.localStorage.getItem("token");
          await axios.post(
            "https://api.nftgenie.ai/finalizemutation",
            {
              txid: mintResult.mintTxId,
              tokenid,
              network: process.env.REACT_APP_SOLANA_NETWORK,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success("Congratulations! Mint succeeded!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Mint failed! Please try again!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error: any) {
      console.log(error.message);
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction Timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
	  console.log("1");
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log("2");	
          message = `SOLD OUT!`;
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      if (publicKey) {
        const balance = await props.connection.getBalance(publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState("processed");
    }
  };



    const renderNfts = () => {
	const copyToClipboard = (text: string) => {
	    navigator.clipboard.writeText(text).then(
		() => {
		    console.log("Text copied to clipboard:", text);
		},
		(err) => {
		    console.error("Could not copy text: ", err);
		}
	    );
	};


    return nftsInWallet.map((nft) => {
      const origurl =
        "https://sol-parasites.s3.us-west-2.amazonaws.com/orig/" +
        String(nft.number) +
        ".jpg";
      const url =
        "https://sol-parasites.s3.us-west-2.amazonaws.com/img/" +
        String(nft.number) +
        ".jpg?" +
        Date.now();

	let display_state = (
	    <div>
        <div>
          Status: alive&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <button
            onClick={() => {
              ChangeNftState(nft.tokenid, "freeze");
            }}
          >
            Freeze
          </button>
          &nbsp;&nbsp;
          <button
            onClick={() => {
              ChangeNftState(nft.tokenid, "resetfreeze");
            }}
          >
            Reset & Freeze
          </button>
          &nbsp;&nbsp;
          <button
            onClick={() => {
              ChangeNftState(nft.tokenid, "stake");
            }}
          >
            Stake
          </button>
          &nbsp;&nbsp;
          <button
            disabled={isMinting}
            onClick={() => {
              handleMint(nft.tokenid);
            }}
          >
            Mint
          </button>
		</div>
		<div>
		<br/>
		ETH Parasite Redemption Code: <small>{nft.signature}</small>
		<br />
		<button onClick={() => copyToClipboard(nft.signature)}>
		Copy to Clipboard
	    </button>
		<br />
		<br />
		</div>
		</div>
      );
      if (nft.nftstate === "staked") {
        display_state = (
          <div>
            Status: staked&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={() => {
                ChangeNftState(nft.tokenid, "unstake");
              }}
            >
              Unstake
            </button>
            &nbsp;&nbsp;
            <button
              disabled={isMinting}
              onClick={() => {
                handleMint(nft.tokenid);
              }}
            >
              Mint
            </button>
          </div>
        );
      } else if (nft.nftstate === "freeze") {
        display_state = (
          <div>
            Status: cyropreserved&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={() => {
                ChangeNftState(nft.tokenid, "unfreeze");
              }}
            >
              Unfreeze
            </button>
            &nbsp;&nbsp;
            <button
              onClick={() => {
                ChangeNftState(nft.tokenid, "stake");
              }}
            >
              Stake
            </button>
            &nbsp;&nbsp;
            <button
              disabled={isMinting}
              onClick={() => {
                handleMint(nft.tokenid);
              }}
            >
              Mint
            </button>
          </div>
        );
      }
	return (
	     <div>
        <h3>SOL Parasite #{nft.number}</h3>
        <img width="256" alt={nft.tokenid} src={origurl} />
        <img width="256" alt={nft.tokenid} src={url} />
        {display_state}
	    </div>
      );
    });
  };

  //&& getNFTsInWallet(shortenAddress(wallet.publicKey.toBase58() || ""))
  //     <div className={`container`}>
  //     <div className={`title`}><h2>Mint</h2></div>
  //     {wallet && (
  // 	<p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
  //     )}
  // Mint Price: 0.1 SOL<br/><br/>
  // {wallet && <p>Redeemed: {itemsRedeemed} / {itemsAvailable}</p>}
  //     <SignMessageButton/>
  //     <MintContainer>
  // {!wallet ? (
  //     <ConnectButton>Connect Wallet</ConnectButton>
  // ) : (
  //     <MintButton
  //     disabled={isSoldOut || isMinting || !isActive}
  //     onClick={onMint}
  //     variant="contained"
  // 	>
  // 	{isSoldOut ? (
  // 	    "SOLD OUT"
  // 	) : isActive ? (
  // 	    isMinting ? (
  // 		<CircularProgress />
  // 	    ) : (
  // 		"MINT"
  // 	    )
  // 	) : (
  // 	    <Countdown
  //             date={startDate}
  //             onMount={({ completed }) => completed && setIsActive(true)}
  //             onComplete={() => setIsActive(true)}
  //             renderer={renderCounter}
  // 		/>
  // 	)}
  //     </MintButton>
  // )}
  // </MintContainer>
  //     <br/>
  //     <br/>
  //     </div>

  return (
    <div>
      <div className={`top-color`}>
        <div className={`header`}>
          <div className={`container`}>
            <div className={`header-container`}>
              <div className={`col`}>
                <div className={`logo`}>
                  <img width="200" src={`../images/logo.svg`} alt="logo" />
                </div>
              </div>
              <div className={`col social-media`}>
                <ul>
                  <li>
                    <a
                      href={"https://twitter.com/SOLParasitesNFT"}
                      target={`_blank`}
                    >
                      <img src={`../images/twitter.svg`} alt="twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://discord.com/invite/uWxfT8FMs8"}
                      target={`_blank`}
                    >
                      <img src={`../images/discord.svg`} alt="discord" />
                    </a>
                  </li>
                </ul>
              </div>
              {!isWalletConnected && (
                <ConnectButton>Connect Wallet</ConnectButton>
              )}
              {publicKey && (
                <div className={`col social-media`}>
                  <ul>
                    <li>
                      {dashboardMode && (
                        <button
                          onClick={() => {
                            setDashboardMode(!dashboardMode);
                            getNFTsInWallet(publicKey.toBase58() || "");
                          }}
                        >
                          Main
                        </button>
                      )}
                      {!dashboardMode && (
                        <button
                          onClick={() => {
                            setDashboardMode(!dashboardMode);
                            getNFTsInWallet(publicKey.toBase58() || "");
                          }}
                        >
                          Dashboard
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {publicKey && dashboardMode && (
          <div className={`container`}>
            <h1>SOL Parasites - Holder Dashboard</h1>
            <div>Wallet: {shortenAddress(publicKey.toBase58() || "")}</div>
            <br />
            Note: Ledger does not currently support signing messages, functions
            like staking/verification will not work with a Ledger wallet.
            <br />
            Please ping the Solana/Ledger team to fix this{" "}
            <a
              style={{ color: "#ffffff" }}
              href="https://github.com/solana-labs/solana/issues/21366#issuecomment-1019457143"
            >
              here
            </a>
            <br />
            <DiscordVerificationForm verifyDiscord={verifyDiscord} />
            <br />
            <div>Verification Token: {verificationtoken}</div>
            <div>{renderNfts()}</div>
          </div>
        )}

        <div className={`hero-block`}>
          <div className={`container`}>
            <h1>
              10,000 NFT Parasites finding their hosts on the Solana Blockchain
            </h1>
          </div>
        </div>

        <div className={`browse-collection-btn-container`}>
          <Link className={`browse-collection-btn`} to="/browse-collection">
            Browse Collection
          </Link>
        </div>

        <div className={`banner-bottom`}>
          <img src={`../images/banner.png`} alt="banner" />
        </div>

        <div
          className={`bot-color`}
          style={{ backgroundImage: "url(../images/bats.png)" }}
        >
          <div className={`road-map`}>
            <div className={`title`}>
              <h2>Road Map</h2>
            </div>
            <div className={`container`}>
              <div className="timeline">
                <div className="n-container left">
                  <div className="content">
                    <h2>Stealth Mint</h2>
                    Stealth Mint starts on 5. November 3:00 PM UTC.
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>Parasites become active</h2>
                    Parasites becoming active 'infesting' their hosts.
                  </div>
                </div>
                <div className="n-container left">
                  <div className="content">
                    <h2>Technical Improvements</h2>
                    The feedback of first mutation cycles was good but we are
                    improving some hickups to make mutations more smooth and
                    improve the system.
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>End of Minting Period</h2>
                    Mint will stop on 14. November 3:00 PM UTC latest - sold out
                    or not.
                  </div>
                </div>
                <div className="n-container left">
                  <div className="content">
                    <h2>Holder Dashboard</h2>
                    Based on community feedback we are developing a dashboard
                    for holders with functionalities to 'freeze' and 'unfreeze'
                    your parasite. As a parasite mutates, you can decide to
                    freeze the state - so it will stay that way until you
                    unfreeze it. You will also be able to 'reset' it into its
                    orginal form. For example before you sell it on the
                    Secondary Market.
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>Traits & Rarity</h2>
                    First of all: all SOL Parasites are completely unique. That
                    said, parasites start out without any traits / attributes.
                    We are working on a system that will allow a parasite to
                    develop traits over time based on the NFT that served as a
                    host for it. The exact workings of this are not yet fully
                    decided and we are involving the community for this. Join
                    our discord to discuss.
                  </div>
                </div>
                <div className="n-container left">
                  <div className="content">
                    <h2>Secondary Markets</h2>
                    We are currently targeting Magic Eden but in generally are
                    in talks with Secondary Markets to find the best fit for our
                    rather special NFT collection.
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>SOL Parasites DAO</h2>
                    We will be forming a SOL Parasites DAO to make community
                    decisions for the future of the project.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`faq`}>
          <div className={`title`}>
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className={`container`}>
            <div className="wrapper">
              <ul>
                <li>
                  <input type="checkbox" id="list-item-1" />
                  <label htmlFor="list-item-1" className="first">
                    What is this Project about
                  </label>
                  <ul>
                    <li>
                      SOL Parasites are 10000 NFT parasites finding their hosts
                      on the Solana Blockchain. Every parasite is unique and
                      custom created.
                    </li>
                  </ul>
                </li>

                <li>
                  <input type="checkbox" id="list-item-2" />
                  <label htmlFor="list-item-2" className="first">
                    When is the mint happening?
                  </label>
                  <ul>
                    <li>
                      The Mint is live now. You can mint directly on this
                      website.
                    </li>
                  </ul>
                </li>
                <li>
                  <input type="checkbox" id="list-item-3" />
                  <label htmlFor="list-item-3">
                    Besides the unique art, what is special about SOL Parasites?
                  </label>
                  <ul>
                    <li>
                      SOL Parasite are the first living NFT creatures. Once a
                      parasite is minted, it will infest its host wallet.
                      <br />
                      That means the SOL Parasite will transform/mutate to a
                      degenerate variant of a random NFT in the host wallet. If
                      the parasite is isolated (nothing else in the wallet) it
                      will return to its original form.
                      <br />
                      <br />
                    </li>
                  </ul>
                </li>

                <li>
                  <input type="checkbox" id="list-item-4" />
                  <label htmlFor="list-item-4">Is this safe?</label>
                  <ul>
                    <li>
                      SOL Parasaites do not literally infest your wallet. Your
                      wallet and all NFTs are safe. Mutations are just
                      modifications of the SOL Parasite to itself.
                    </li>
                  </ul>
                </li>

                <li>
                  <input type="checkbox" id="list-item-5" />
                  <label htmlFor="list-item-5">
                    Are there secondary sales royalties?
                  </label>
                  <ul>
                    <li>Yes, royalties are set at 9%.</li>
                  </ul>
                </li>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </ul>
            </div>
          </div>
        </div>

        <div className={`footer`}>
          <div className={`container`}>
            <div className={`footer-block`}>
              <div className={`col`}>
                <h5>Powered by</h5>
                <ul>
                  <li>
                    <img src={`../images/pow-1.svg`} alt="pow-1" />
                  </li>
                  <li>
                    <img src={`../images/pow-3.svg`} alt="pow-3" />
                  </li>
                </ul>
              </div>
              <div className={`col`}>
                <p>Copyright 2022 by SOLParasites.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
