import { useEffect, useState } from "react";
import styled from "styled-components";
import "./style.css";
import { Button } from "@material-ui/core";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { useWallet } from "@solana/wallet-adapter-react";
import bs58 from "bs58";
import React, { FC, useCallback } from "react";
import { sign } from "tweetnacl";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
  CandyMachineAccount,
} from "./candy-machine";

import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const items = Array.from(Array(10000).keys());

function Items({ currentItems }: any) {
  return (
    <div className="sol-items">
      {currentItems &&
        currentItems.map((item: number) => (
          <div key={item} className="sol-item">
            <img
              src={`https://sol-parasites.s3.us-west-2.amazonaws.com/orig/${item}.jpg`}
              alt={`SOL ${item}`}
              className="sol-item__img"
            />
            <p className="sol-item__title">SOL Parasite #{item}</p>
          </div>
        ))}
    </div>
  );
}

function PaginatedItems({ itemsPerPage }: any) {
  const [currentItems, setCurrentItems] = useState<null | any[]>(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = useCallback(
    (event: any) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    },
    [itemsPerPage]
  );

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        nextLabel="Next"
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={undefined}
      />
    </>
  );
}

export const SignMessageButton: FC = () => {
  const { publicKey, signMessage } = useWallet();

  const onClick = useCallback(async () => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes
      var inst = {
        id: "9yfnGB76bdzUNegNd17AetAD9p8Lca8w7Hf1CntJMSHu",
        cmd: "freeze",
      };
      const message = new TextEncoder().encode(JSON.stringify(inst));
      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes()))
        throw new Error("Invalid signature!");

      alert(`Message signature: ${bs58.encode(signature)}`);

      var instruction = {
        update: [
          {
            instruction: inst,
            signature: bs58.encode(signature),
            pubkey: publicKey.toBase58(),
          },
        ],
      };

      var response = fetch(
        "https://boyw9caow7.execute-api.us-west-2.amazonaws.com/prod/parasites/update/",
        {
          method: "POST",
          body: JSON.stringify(instruction),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      ).then((response) => {
        console.log(response);
        alert(response);
      });
    } catch (error: any) {
      alert(`Signing failed: ${error?.message}`);
    }
  }, [publicKey, signMessage]);

  return signMessage ? (
    <button onClick={onClick} disabled={!publicKey}>
      Sign Message
    </button>
  ) : null;
};

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

export interface BrowseCollectionProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const BrowseCollection = (props: BrowseCollectionProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [nftsInWallet, setNftsInWallet] = useState<any[]>([]);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));
  const [dashboardMode, setDashboardMode] = useState(false);

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const candyMachine = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(candyMachine.state.itemsAvailable);
      setItemsRemaining(candyMachine.state.itemsRemaining);
      setItemsRedeemed(candyMachine.state.itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(
        candyMachine.state.goLiveDate
          ? new Date(candyMachine.state.goLiveDate?.toNumber() * 1000)
          : new Date(props.startDate)
      );
      setCandyMachine(candyMachine);
    })();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  //{wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}
  //{wallet && <p>Total Available: {itemsAvailable}</p>}
  //{wallet && <p>Redeemed: {itemsRedeemed}</p>}

  //          <p>Fair mint (see technical details)</p>

  //r = requests.post('https://thrumming-crimson-meadow.solana-mainnet.quiknode.pro/cef7b4f3efee6360cde3b040c3a0ae4d4523a503/',

  const getNFTsInWallet = (wallet: any) => {
    var instruction = { wallet: wallet };

    var response = fetch(
      "https://boyw9caow7.execute-api.us-west-2.amazonaws.com/prod/parasites/",
      {
        method: "POST",
        body: JSON.stringify(instruction),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    ).then((response) => {
      console.log(response);
      response.json().then((data) => {
        var nfts = data.parasites;
        //alert(nfts[0].pubkey);
        var sortednfts = nfts.sort(function (a: any, b: any) {
          return a.number - b.number;
        });
        setNftsInWallet(sortednfts);
        //alert(nftsInWallet.length);
        console.log(sortednfts);
      });
    });
  };

  const { publicKey, signMessage } = useWallet();

  const ChangeNftState = async (tokenid: any, cmd: any) => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Encode anything as bytes
      var inst = {
        id: tokenid,
        cmd: cmd,
      };
      const message = new TextEncoder().encode(JSON.stringify(inst));
      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes()))
        throw new Error("Invalid signature!");

      //alert(`Message signature: ${bs58.encode(signature)}`);

      var instruction = {
        update: [
          {
            instruction: inst,
            signature: bs58.encode(signature),
            pubkey: publicKey.toBase58(),
          },
        ],
      };

      var response = fetch(
        "https://boyw9caow7.execute-api.us-west-2.amazonaws.com/prod/parasites/update/",
        {
          method: "POST",
          body: JSON.stringify(instruction),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      ).then((response) => {
        console.log(response);
        //alert(response);
        getNFTsInWallet(publicKey.toBase58() || "");
      });
    } catch (error: any) {
      alert(`Signing failed: ${error?.message}`);
    }
  };

  const renderNfts = () => {
    return nftsInWallet.map((nft) => {
      var origurl =
        "https://sol-parasites.s3.us-west-2.amazonaws.com/orig/" +
        String(nft.number) +
        ".jpg";
      var url =
        "https://sol-parasites.s3.us-west-2.amazonaws.com/img/" +
        String(nft.number) +
        ".jpg?" +
        Date.now();
      var display_state = (
        <div>
          Status: alive&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <button
            onClick={() => {
              ChangeNftState(nft.tokenid, "freeze");
            }}
          >
            Freeze
          </button>
          &nbsp;&nbsp;
          <button
            onClick={() => {
              ChangeNftState(nft.tokenid, "resetfreeze");
            }}
          >
            Reset & Freeze
          </button>
        </div>
      );
      if (nft.nftstate == "freeze") {
        display_state = (
          <div>
            Status: cyropreserved&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              onClick={() => {
                ChangeNftState(nft.tokenid, "unfreeze");
              }}
            >
              Unfreeze
            </button>
          </div>
        );
      }
      return (
        <div>
          <h3>SOL Parasite #{nft.number}</h3>
          <img width="256" alt={nft.tokenid} src={origurl} />
          <img width="256" alt={nft.tokenid} src={url} />
          <br />
          {display_state}
          <br /> <br />
        </div>
      );
    });
  };

  //&& getNFTsInWallet(shortenAddress(wallet.publicKey.toBase58() || ""))
  //     <div className={`container`}>
  //     <div className={`title`}><h2>Mint</h2></div>
  //     {wallet && (
  // 	<p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
  //     )}
  // Mint Price: 0.1 SOL<br/><br/>
  // {wallet && <p>Redeemed: {itemsRedeemed} / {itemsAvailable}</p>}
  //     <SignMessageButton/>
  //     <MintContainer>
  // {!wallet ? (
  //     <ConnectButton>Connect Wallet</ConnectButton>
  // ) : (
  //     <MintButton
  //     disabled={isSoldOut || isMinting || !isActive}
  //     onClick={onMint}
  //     variant="contained"
  // 	>
  // 	{isSoldOut ? (
  // 	    "SOLD OUT"
  // 	) : isActive ? (
  // 	    isMinting ? (
  // 		<CircularProgress />
  // 	    ) : (
  // 		"MINT"
  // 	    )
  // 	) : (
  // 	    <Countdown
  //             date={startDate}
  //             onMount={({ completed }) => completed && setIsActive(true)}
  //             onComplete={() => setIsActive(true)}
  //             renderer={renderCounter}
  // 		/>
  // 	)}
  //     </MintButton>
  // )}
  // </MintContainer>
  //     <br/>
  //     <br/>
  //     </div>

  return (
    <div>
      <div className={`top-color`}>
        <div className={`header`}>
          <div className={`container`}>
            <div className={`header-container`}>
              <div className={`col`}>
                <div className={`logo`}>
                  <img width="200" src={`../images/logo.svg`} />
                </div>
              </div>
              <div className={`col social-media`}>
                <ul>
                  <li>
                    <a
                      href={"https://twitter.com/SOLParasitesNFT"}
                      target={`_blank`}
                    >
                      <img src={`../images/twitter.svg`} />
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://discord.com/invite/uWxfT8FMs8"}
                      target={`_blank`}
                    >
                      <img src={`../images/discord.svg`} />
                    </a>
                  </li>
                </ul>
              </div>
              {!wallet && <ConnectButton>Connect Wallet</ConnectButton>}
              {wallet && (
                <div className={`col social-media`}>
                  <ul>
                    <li>
                      {dashboardMode && (
                        <button
                          onClick={() => {
                            setDashboardMode(!dashboardMode);
                            getNFTsInWallet(wallet.publicKey.toBase58() || "");
                          }}
                        >
                          Main
                        </button>
                      )}
                      {!dashboardMode && (
                        <button
                          onClick={() => {
                            setDashboardMode(!dashboardMode);
                            getNFTsInWallet(wallet.publicKey.toBase58() || "");
                          }}
                        >
                          Dashboard
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {wallet && dashboardMode && (
          <div className={`container`}>
            <h1>SOL Parasites - Holder Dashboard</h1>
            <div>
              Wallet: {shortenAddress(wallet.publicKey.toBase58() || "")}
            </div>
            <div>{renderNfts()}</div>
          </div>
        )}

        <div className={`hero-block`}>
          <div className={`container`}>
            <h1>
              10,000 NFT Parasites finding their hosts on the Solana Blockchain
            </h1>
          </div>
        </div>

        <div className={`browse-collection-btn-container`}>
          <Link to="/" className={`browse-collection-btn`}>
            Back to Home
          </Link>
        </div>

        <div className="browse-collection-container">
          <PaginatedItems itemsPerPage={100} />
        </div>

        <div className={`footer`}>
          <div className={`container`}>
            <div className={`footer-block`}>
              <div className={`col`}>
                <h5>Powered by</h5>
                <ul>
                  <li>
                    <img src={`../images/pow-1.svg`} />
                  </li>
                  <li>
                    <img src={`../images/pow-3.svg`} />
                  </li>
                </ul>
              </div>
              <div className={`col`}>
                <p>Copyright 2021 by SOLParasites.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default BrowseCollection;
