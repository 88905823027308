import * as React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';

export default class NameForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {value: ''};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {    this.setState({value: event.target.value});  }
  handleSubmit(event) {
      this.props.verifyDiscord(this.state.value);
      event.preventDefault();
  }

  render() {
      return (
	  <div>
	      <h3>Holder Verification:</h3>
	      <form onSubmit={this.handleSubmit}>
		  <label>
		      Discord Username:
		      <input type="text" value={this.state.value} onChange={this.handleChange} />
		  </label>
		  <input type="submit" value="Generate Verification Code" />
	      </form>
	  </div>
      );
  }
}
